import React, { useState } from "react";
import dateFormat from "dateformat";
import { Avatar } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, MenuItem, Menu } from "@mui/material";
import { EditOperatorIcon, DeleteOperatorIcon } from "./../../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePrivateNote,
  updatePrivateNote,
} from "../../../../slices/todolist";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import DoneIcon from "@mui/icons-material/Done";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ConvertingArabicNumberToEnglish } from "../../../../utilities/toEnglishNumber";
const PrivateNote = ({ item, conversationId, websiteId, showSnackbar }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { statusEditPrivateNote } = useSelector((state) => state.todolist);
  const [editValue, setEditValue] = useState(item.content);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [edit, setEdit] = useState(false);
  const { i18n, t } = useTranslation();
  return (
    <div className="privace-note-container">
      <Avatar
        className="privace-note-avatar"
        alt="privace-note-avatar"
        src={item?.client.avatar}
        sx={{ height: "70px", width: "70px" }}
      />
      <div className="privace-note-content">
        <div>
          <span className="privace-note-operator">
            {item.client.firstName + " " + item.client.lastName}
          </span>
          <span className="privace-note-date">
            {/* {new Date(item.createdAt).toLocaleString(i18n.language)} */}
            {ConvertingArabicNumberToEnglish(
              moment(item?.createdAt).format("hh:mm ")
            )}
            {t("On")}
            {new Date(item?.createdAt).toLocaleDateString()}
          </span>
          <IconButton onClick={handleClick} style={{ marginTop: "-6px" }}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            className="menu-item"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem
              className="menu-item"
              onClick={() => {
                handleClose();
                dispatch(
                  deletePrivateNote({
                    conversationID: conversationId,
                    websiteID: websiteId,
                    id: item._id,
                  })
                ).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    showSnackbar({
                      variant: "success",
                      message: t("Private Note Deleted Successfully"),
                    });
                  } else {
                    showSnackbar({
                      variant: "error",
                      message: res?.error?.message,
                    });
                  }
                });
              }}
            >
              {" "}
              <LazyLoadImage src={DeleteOperatorIcon} alt="trashIcon" />{" "}
              {t("Delete")}
            </MenuItem>
            <MenuItem
              className="menu-item"
              onClick={() => {
                handleClose();
                setEdit(true);
              }}
            >
              {" "}
              <LazyLoadImage
                src={EditOperatorIcon}
                alt="EditOperatorIcon"
              />{" "}
              {t("Edit")}
            </MenuItem>
          </Menu>
        </div>
        <span className="privace-note-paragraph">
          {edit ? (
            <div style={{ display: "flex", gap: "10px", width: "100%" }}>
              <div
                className="edit-note privace-note-input-container "
                style={{ width: "100%!important" }}
              >
                <textarea
                  id="edit-Private-Note"
                  value={editValue}
                  onChange={(e) => setEditValue(e.target.value)}
                />
              </div>
              <div className="edit-icons-wrapper">
                <IconButton
                  onClick={() =>
                    dispatch(
                      updatePrivateNote({
                        conversationID: conversationId,
                        websiteID: websiteId,
                        id: item._id,
                        data: { content: editValue },
                      })
                    ).then((res) => {
                      if (res.meta.requestStatus === "fulfilled") {
                        setEdit(false);
                        showSnackbar({
                          variant: "success",
                          message: t("Private Note Updated Successfully"),
                        });
                      } else {
                        showSnackbar({
                          variant: "error",
                          message: res?.error?.message,
                        });
                      }
                    })
                  }
                  disabled={statusEditPrivateNote === "loading"}
                >
                  <DoneIcon
                    style={{
                      color: "#7BC600",
                    }}
                  />
                </IconButton>
                <IconButton onClick={() => setEdit(false)}>
                  <NotInterestedIcon style={{ color: "#ED3863" }} />
                </IconButton>
              </div>
            </div>
          ) : (
            item.content
          )}
        </span>
      </div>
    </div>
  );
};

export default PrivateNote;
